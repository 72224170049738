exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asistencia-remota-js": () => import("./../../../src/pages/asistencia-remota.js" /* webpackChunkName: "component---src-pages-asistencia-remota-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paginas-web-js": () => import("./../../../src/pages/paginas-web.js" /* webpackChunkName: "component---src-pages-paginas-web-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-servicios-cambio-hdd-a-ssd-js": () => import("./../../../src/pages/servicios/cambio-hdd-a-ssd.js" /* webpackChunkName: "component---src-pages-servicios-cambio-hdd-a-ssd-js" */),
  "component---src-pages-servicios-instalacion-windows-limpia-js": () => import("./../../../src/pages/servicios/instalacion-windows-limpia.js" /* webpackChunkName: "component---src-pages-servicios-instalacion-windows-limpia-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-servicios-limpieza-de-virus-y-notificaciones-js": () => import("./../../../src/pages/servicios/limpieza-de-virus-y-notificaciones.js" /* webpackChunkName: "component---src-pages-servicios-limpieza-de-virus-y-notificaciones-js" */),
  "component---src-pages-servicios-limpieza-interna-cambio-pasta-termica-gamer-js": () => import("./../../../src/pages/servicios/limpieza-interna-cambio-pasta-termica-gamer.js" /* webpackChunkName: "component---src-pages-servicios-limpieza-interna-cambio-pasta-termica-gamer-js" */),
  "component---src-pages-servicios-limpieza-interna-cambio-pasta-termica-js": () => import("./../../../src/pages/servicios/limpieza-interna-cambio-pasta-termica.js" /* webpackChunkName: "component---src-pages-servicios-limpieza-interna-cambio-pasta-termica-js" */),
  "component---src-pages-servicios-limpieza-interna-y-externa-js": () => import("./../../../src/pages/servicios/limpieza-interna-y-externa.js" /* webpackChunkName: "component---src-pages-servicios-limpieza-interna-y-externa-js" */),
  "component---src-pages-ubicacion-js": () => import("./../../../src/pages/ubicacion.js" /* webpackChunkName: "component---src-pages-ubicacion-js" */)
}

