module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WSK4QKE3LD"],"gtagConfig":{"anonymize_ip":false,"cookie_expires":0},"pluginConfig":{"head":false,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://rescatetecnicoit.cl","delayOnRouteUpdate":0,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/logo.png","name":"Rescate Tecnico IT","short_name":"Rescate Tecnico IT","start_url":"/","background_color":"#374eab","theme_color":"#374eab","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"552e3f08641018257dba1cbf600b1d48"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"C:\\Users\\Gaston\\Dropbox\\plataformas\\Rescate Tecnico IT\\web agosto 2024\\rescate-tecnico-it-2024/src/intl","languages":["es","en"],"defaultLanguage":"es"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
